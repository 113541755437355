<template>
  <div>
    <editBtn href="#" v-if="!create" class="flix-btn flix-btn-default" :onClick="function () { create = true }"><template v-slot:text>{{ $t('message.edit', {name: $tc('message.organisation', 1)}) }}</template></editBtn>
    <transition name="flixFadeIn">
      <div v-if="create" class="flix-well" >
        <div>
          <list :key="$store.state.organisations.organisationsKey" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    'list' () { return import('./list.vue') }
  },
  props: {},
  data () {
    return {
      create: true
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    close () {
      this.create = false
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
